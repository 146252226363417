<template>
    <div class="container">


        <h2 class="head2 mb-5">{{Lang.get('link10')}}</h2>
        <div class="text-green bold Truculenta text-37" style="max-width:760px;margin:auto;" v-html="Lang.get('text10')"></div>
        <div class="mt-5 mb-5">

            <img src="../assets/Surdurulebilirlik-2.png" class="img-fluid" />
        </div>
        <div v-if="Lang.SelectedLanguage=='tr'">

            <div style="max-width:570px;margin:auto;">
                <p>
                    Ülker Bisküvi’de altı başlık altında yürüttüğümüz sürdürülebilirlik çalışmalarımızı 2020 yılında da artırarak devam ettirdik. 2020, Ülker Bisküvi açısından sürdürülebilirlik faaliyetlerimizin küresel pandemi sürecine rağmen ilerlediği bir yıl oldu. Düzenli olarak gerçekleştirdiğimiz sürdürülebilirlik platformu toplantılarıyla hedeflerimiz doğrultusunda kaydedilen ilerleme toplantılarıyla takip ediliyor. 2020 yılında gerçekleştirdiğimiz platform toplantılarında tüm birimler için performans göstergelerini güncellendik.
                </p>  <p></p>
                Liderlik hedefimizden yola çıkarak yaptığımız çalışmalar, sürdürülebilirlik performansını değerlendiren endeksler tarafından tescilleniyor. Dünya standartlarında kabul gören çevresel, sosyal ve kurumsal yönetim uygulamalarımızla, lider sürdürülebilirlik endekslerinde yer almaya devam ettik. 2016 yılından beri FTSE4Good Gelişmekte Olan Piyasalar Endeksi’nde ve 2015 yılından beri Borsa İstanbul Sürdürülebilirlik Endeksi’nde yer alıyoruz. Edie Sürdürülebilirlik Ödülleri’nde ise su yönetimi, lojistik ve sürdürülebilirlik raporlaması kategorilerinde finale kalan şirketler arasındayız.
                </p>  <p>
                    Faaliyetlerimizin tamamını ürünlerimizin toprağa, suya ve doğaya bağlı olduğu bilinciyle gerçekleştiriyoruz. Sürdürülebilirlik stratejimizin en önemli alanlarından biri olarak benimsediğimiz çevre ile olan ilişkimizi, “daha mutlu bir dünyaya katkı” misyonumuz belirliyor. Ülker Bisküvi’de çevresel sürdürülebilirlik konularını iklim değişikliği ve enerji, doğal kaynak yönetimi, ambalaj ve atık yönetimi ile biyoçeşitlilik olarak farklı alanlarda ele alıyoruz. Ayrıca, Çevre Politikamız çerçevesinde kurguladığımız Çevre Yönetim Sistemi’ne göre faaliyetlerimizin çevreye etkilerini yönetiyoruz. Türkiye’deki tüm fabrikalarımızda, ISO 14001, ISO 9001 ve ISO 22001 sertifikalarına sahibiz. Gebze Fabrikamız ise BREEM Yeşil Bina Serfikası’nı GOOD seviyesinde almaya hak kazandı ve alanında bu sertifikaya sahip ilk bina oldu.
                </p>  <p>
                    Sürdürülebilirlik çalışmalarımız konusunda uzun vadeli hedefler belirledik. 2014 yılında belirlediğimiz 10 yıllık hedefler doğrultusunda çevresel anlamda en önemli hedef karbon salımı artışı olmadan büyümeye devam ediyor. Ayrıca, birim üretim başına su kullanımını %30 azaltma, %100 geri dönüşümle düzenli depolama alanlarına sıfır atık gönderme, iade ambalaj oranını %50 azaltma gibi hedefler de belirledik.
                </p>
            </div>
            <div class="deep-note-green mb-5">
                <div style="max-width:450px;">
                    Sürdürülebilirlik çalışmalarımız kapsamında, birim üretim başına su kullanımını %30 azaltma, %100 geri dönüşümle düzenli depolama alanlarına sıfır atık gönderme ve iade ambalaj oranını %50 azaltma gibi uzun vadeli hedefler belirledik.
                </div>
            </div>
        </div>
        <div v-if="Lang.SelectedLanguage=='en'">
            <div style="max-width:570px;margin:auto;">
                <p>
                    The core of our sustainability activities goes back to the day Ülker was founded; based on our founder Sabri Ülker’s “wasteless company model.” Mr. Sabri Ülker created a company culture that neither wastes a gram of flour, a drop of water, nor the labor of man. He left this culture to the next generations. And with the strength he gave us, we’re trying to develop this heritage, and pass it on to the next generations.
                </p>  <p>
                    We believe that it is our primary responsibility to consciously use world’s limited resources, to manage our talents correctly, and to respond both to our world that has an increasing population and to our consumers who have changing expectations and needs. Because our leadership position in the sector requires us to be a pioneer and an example in the field of sustainability.
                </p>  <p>
                    We focused our sustainability efforts at Ülker Bisküvi on generating long-term value and establishing the necessary strategies, policies and objectives in order to manage the risks that may arise due to economic, environmental and social factors. We follow-up the compliance of these efforts with the Corporate Governance Principles as well as with the decisions, management and processes of the Company. The Sustainability Platform is represented at an executive level by the president, vice president and general manager. The Platform continuously monitors the environmental, social and administrative developments, most of which occur outside the direct influence of Ülker Bisküvi, and fulfills its predefined duties and responsibilities.
                </p>  <p>
                    The sustainability efforts carried out under six categories at Ülker Bisküvi continued at an accelerated pace in 2020. Ülker Bisküvi progressed its sustainability activities despite COVID-19 pandemic around the globe. We follow our progress in line with our goals at regularly held sustainability platform meetings. At the platform meetings held in 2020, we updated the performance indicators for all units.
                </p>  <p>
                    Activities that we carry out in line with our leadership goal are officially recognized by the indices that assess sustainability performances. Thanks to our world-class environmental, social and corporate governance practices, we maintained our position in the leading sustainability indices. We have been a part of the FTSE4Good Emerging Markets Index since 2016, and the Borsa Istanbul Sustainability Index since 2015. We are among the companies that made it to the finals at the Edie Sustainability Awards in the categories of water management, logistics and sustainability reporting.
                </p>  <p>
                    By focusing on an active life, we inform all consumers and other stakeholders in this regard transparently while focusing on facilitating and simplifying life for the society.


                </p>
            </div>

        </div>
        <router-link :to="'/'+Lang.SelectedLanguage" class="backhome"> {{Lang.get('backhome')}} </router-link>

        <a v-if="Lang.SelectedLanguage=='tr'" target="_blank" href="/files/Sürdürülebilirlik Çalışmaları.pdf" class="download">{{Lang.get('download')}}</a>
        <a v-if="Lang.SelectedLanguage=='en'" target="_blank" href="/files/Sustainability.pdf" class="download">{{Lang.get('download')}}</a>
    </div>




</template>

<script>
    import lng from "../store/languages";

    export default {
        name: 'Chairman',
        components: {},
        data() {
            return {
                Lang: lng,
            }
        }
    }</script>
